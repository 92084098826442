import React, { useState, useEffect, useMemo } from "react"
import Layout from "./../../../components/layout"
import axios from 'axios'
import { API } from "./../../../config/api"
import HeaderPages from "./../../../components/header-pages"
import { useLocation } from "@reach/router"
import { Container } from "react-bootstrap"
import * as HeroIcons from 'react-icons/hi'
import { navigate } from "gatsby"
import Seo from "../../../components/seo"


const HelpInformationDetail = ({ params }) => {
    const param = params['param']
    const location = useLocation()
    const [DetailHelpData, setDetailHelpData] = useState()

    const getDetailHelp = () => {
        const response = axios.get(`${API.URL}/api/v1/help-information/desc?information_id=&slug=${param}`).then(response => {
            if (response?.data?.status == true) {
                setDetailHelpData(response?.data?.data)
                console.log("Success Get Detail Help: ", response?.data);
            } else {
                console.warn("Error Get Detail Help: ", response);
            }
        }).catch(err => {
            console.error("Error Get Detail Help: ", err);
        })

        return response
    }

    useEffect(() => {
        getDetailHelp()
    }, [])


    return (
        <Layout pageTitle={`Informasi Bantuan | ${DetailHelpData?.question}`}>
            <Seo title={`Informasi Bantuan | ${DetailHelpData?.question}`} />
            <HeaderPages />
            <div style={{ height: 150 }} />
            <Container>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <HeroIcons.HiArrowLeft size={30} color='black' style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                    <div style={{ width: 50 }} />
                    <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: DetailHelpData?.question }} />
                </div>
                <div style={{ height: 50 }} />
                <div style={{ color: 'black' }} dangerouslySetInnerHTML={{ __html: DetailHelpData?.answer }} />
            </Container>
            {/* <DataTermCondition /> */}
            {/* <Footer /> */}
        </Layout>
    )
}

export default HelpInformationDetail
